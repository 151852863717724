import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import MdxLayout from 'components/mdx-layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <MdxLayout title="Using in Production" subline="Tips for using Backbreeze in production">
    {children}
  </MdxLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "postcss-plugins"
    }}>{`PostCSS plugins`}</h2>
    <p><a parentName="p" {...{
        "href": "https://postcss.org/"
      }}>{`PostCSS`}</a>{` is a tool to modify CSS with JavaScript. On its own PostCSS does not do much, it works by chaining plugins together that modify CSS code sequentially. To setup PostCSS in your project please refer to the `}<a parentName="p" {...{
        "href": "https://github.com/postcss/postcss#usage"
      }}>{`PostCSS usage documentation`}</a>{`. To use Backbreeze in production the usage of two plugins is recommended:`}</p>
    <h3 {...{
      "id": "remove-unused-css-with-purgecss"
    }}>{`Remove unused CSS with PurgeCSS`}</h3>
    <p>{`Backbreeze generates a large amount of CSS. It is highly likely that any given project will only use a fraction of the generated CSS. To prevent unused CSS to be delivered in production the use of `}<a parentName="p" {...{
        "href": "https://www.purgecss.com/"
      }}>{`PurgeCSS`}</a>{` is highly recommended. The `}<a parentName="p" {...{
        "href": "https://www.purgecss.com/"
      }}>{`PurgeCSS documentation`}</a>{` has a good overview of how to used it with different build systems.`}</p>
    <p>{`When using PurgeCSS it is important to configure the `}<inlineCode parentName="p">{`extractors`}</inlineCode>{` option properly so classes with special characters used in Backbreeze are handled correctly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// In your PurgeCSS configuration
extractors: [
  {
    extractor: (content) => {
      return content.match(/[\\w-/:%@.]+(?<!:)/g) || [];
    },
    extensions: ['.html', '.js'], // all file the extensions the extractor should be applied to
  },
];
`}</code></pre>
    <p>{`If you use other special characters trough configuration you should include them in the regular expression.`}</p>
    <h4 {...{
      "id": "use-only-in-production"
    }}>{`Use only in production`}</h4>
    <p>{`PurgeCSS takes some time to evaluate all CSS and eliminate the unused parts. Therefor it should not run during development. Make sure yo only use it in production builds.`}</p>
    {
      /* @TODO add example */
    }
    <h3 {...{
      "id": "add-vendor-prefixing-with-autoprefixer"
    }}>{`Add vendor prefixing with Autoprefixer`}</h3>
    <p>{`Backbreeze generated CSS does not include vendor prefixes. It is recommended to use `}<a parentName="p" {...{
        "href": "https://github.com/postcss/autoprefixer"
      }}>{`Autoprefixer`}</a>{` to ensure CSS compatibility.`}</p>
    <h2 {...{
      "id": "disabling-utilities"
    }}>{`Disabling utilities`}</h2>
    <p>{`To disable utilities set the property config variable to `}<inlineCode parentName="p">{`false`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// Disable the text-align utility classes
$bb-text-align-config: false;
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      